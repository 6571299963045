import React from 'react';

import Layout from 'components/Layout';
import Container from 'components/Container';
import blackDiamonds from "../../images/black-diamonds.svg";
import {Link} from "gatsby";
import Seo from "../../components/Seo";
import mediakit1 from "../../images/mediakit/lawnmediakit2.png";
import mediakit2 from "../../images/mediakit/lawnmediakit3.png";
import mediakit3 from "../../images/mediakit/lawnmediakit4.png";
import mediakit4 from "../../images/mediakit/lawnmediakit5.png";
import mediakit5 from "../../images/mediakit/lawnmediakit6.png";
import mediakit from "../../images/mediakit/lawnmediakit1.png";
import census1 from "../../images/census/landscapercensus1.png";
import census2 from "../../images/census/landscapercensus2.png";
import census3 from "../../images/census/landscapercensus3.png";
import census4 from "../../images/census/landscapercensus4.png";
import census5 from "../../images/census/landscapercensus5.png";
import census6 from "../../images/census/landscapercensus6.png";
import ngiste from "../../images/cannabis/ngiste-abebe.png";
import troy from "../../images/cannabis/troy-meadows.png";

const MiscPage = () => {
    return (
            <Layout pageName="misc">
                <Seo
                        title={'Grab Bag'}
                />
                <Container>
                    <div className="grid grid-cols-1 gap-10">
                        <div className="grid md:grid-cols-6 md:gap-10 content-center md:mb-16">
                            <img src={census1} alt="Cannabis Dispensary" className="md:col-span-3 w-full"/>
                            <div className="text-left md:col-span-3 lg:col-span-2 mt-4 md:mt-0">
                                <h2 className="text-7xl font-serif">Grab Bag</h2>
                                <h3 className="font-mono text-xl mt-4">2020 — Present</h3>
                                <img src={blackDiamonds} alt="" className="mt-6"/>
                                <p className="mt-8">An assortment of projects that sit outside the boundaries of straight-forward magazine work, such as one of my favorite packages ever, The Landscaper Census (in collaboration with the inimitable Alex Eben Meyer), the 2022 Lawn & Landscape Media Planner and web assets for Cannabis Business Times inaugural Cannabis Leadership Awards.

                                </p>
                            </div>
                        </div>
                        <img src={census2} alt="" className="max-w-full" />
                        <img src={census3} alt="" className="max-w-full" />
                        <img src={census4} alt="" className="max-w-full" />
                        <img src={census5} alt="" className="max-w-full" />
                        <img src={census6} alt="" className="max-w-full" />
                        <img src={mediakit} alt="" className="max-w-full border-t-2 pt-16 mt-8" />
                        <img src={mediakit1} alt="" className="max-w-full" />
                        <img src={mediakit2} alt="" className="max-w-full" />
                        <img src={mediakit3} alt="" className="max-w-full" />
                        <img src={mediakit4} alt="" className="max-w-full" />
                        <img src={mediakit5} alt="" className="max-w-full" />
                        <div className="grid md:grid-cols-2 gap-10 border-t-2 pt-16 mt-8">
                            <img src={ngiste} alt="" className="max-w-full"/>
                            <img src={troy} alt="" className="max-w-full"/>
                        </div>
                        <div className="md:mt-16">
                            <Link to="/work/lawn-landscape" className="text-4xl font-serif hover:text-pink transition-colors duration-200">Next Project: Lawn & Landscape</Link>
                        </div>
                    </div>
                </Container>
            </Layout>
    );
};

export default MiscPage;
